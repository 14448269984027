@font-face {
  font-family: 'Museo Slab';
  src: url('https://res.cloudinary.com/ninth-wave/raw/upload/v1571946937/Console/fonts/MuseoSlab500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('https://res.cloudinary.com/ninth-wave/raw/upload/v1571946937/Console/fonts/MuseoSans500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
